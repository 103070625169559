import React from "react"
import { graphql } from "gatsby"

import FourchetteIcon from "../components/svg/fourchette-icon"
import LocalizedLink from "../components/localizedLink"

import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { IntersectionObserver } from "../components/utils/intersection-observer"
import { FadeInUp } from "../components/animations/fadeInUp"

class ProgramPage extends React.Component {

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.program

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO title="Programme" />

          <IntersectionObserver className="u-pd-vt-xl">
            <div className="l-container u-pd-hz-l">
              <FadeInUp yOffset={50} delay={100}>
                <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-l">
                  <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                  {content.title}
                </h1>
              </FadeInUp>
              <FadeInUp yOffset={50} delay={200}>
                {content.desc.map((item, index) => {
                  return (
                    <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={"program-desc--" + index}>{item}</p>
                  )
                })}
              </FadeInUp>
            </div>{/*
            <FadeInUp yOffset={200} delay={300} className="l-container-md u-pd-vt-xxl">
              <div className="u-bordered u-bg-white u-pd-l u-pd-vt-l@main u-pd-hz-xxl@main">
                {content.keypoints.map((item, index) => {
                  return (
                    <p className="u-flex u-flex-center-vt u-fs-m u-primary u-bold u-mg-bottom-s" key={"program-keypoints-" + index}>
                      <FourchetteIcon className="u-mg-right-m" style={{ width: "30px", height: "12px" }}/> {item}
                    </p>
                  )
                })}
              </div>
              </FadeInUp>*/}
          </IntersectionObserver>

          {/* Au menu */}
          <IntersectionObserver className="u-border-top">
            <div className="l-container">

              <div className="l-grid">

                <div className="l-col-12 l-col-6@main u-pd-hz-m u-pd-vt-xl u-pd-xl@main u-flex u-flex-dir-col u-flex-center-hz">

                  <FadeInUp yOffset={50} duration={0.8}>
                    <div className="u-bordered u-bg-white u-pd-xl">
                      <h2 className="c-h2 u-uppercase u-red u-mg-bottom-m">{content.program.title}</h2>
                      {content.program.desc.map((item, index) => {
                        return (
                          <p className="u-mg-bottom-l" key={"program-desc--" + index}>{item}</p>
                        )
                      })}
                      <ul className="u-list u-list--red">
                        {content.program.list.map((item, index) => {
                          return (
                            <li className="" key={"program-list-" + index}>{item}</li>
                          )
                        })}
                      </ul>
                      <div className="u-mg-top-m u-pd-m">
                        <p className="u-italic u-bold">{content.program.asterisk}</p>
                      </div>
                    </div>

                    <div className="l-grid u-bordered u-border-0-top">
                      <div className="l-col-12 l-col-6@main u-bg-white u-border-right@main u-flex u-flex-center-vt u-flex-center-hz u-pd-m">
                        <span className="u-fs-s u-semibold u-center">{content.program.punchline}</span>
                      </div>
                      <div className="l-col-12 l-col-6@main">
                        <LocalizedLink to="/thematiques" className="c-btn c-btn--red u-border-0-top@main u-border-0-right u-border-0-bottom u-border-0-left u-width-full u-height-full">
                          <span className="u-fs-s">{content.program.cta} <br/> <FourchetteIcon className="u-mg-top-xs"/></span>
                          <span>{content.program.cta_hover}</span>
                        </LocalizedLink>
                      </div>
                    </div>
                  </FadeInUp>
                </div>

                <div className="l-col-12 l-col-6@main u-flex u-flex-end u-flex-center-vt u-pd-hz-m u-pd-vt-xl u-pd-xl@main">
                  <FadeInUp yOffset={50} duration={0.8} className="u-pd-l u-pd-xxl@main u-width-full u-height-full">
                    <Img className="" fluid={data.imageProgram.childImageSharp.fluid} />
                  </FadeInUp>
                </div>

              </div>

            </div>

          </IntersectionObserver>

          {/* Chefs de partie */}
          <IntersectionObserver className="">
            <div className="l-container">

              <div className="l-grid u-flex-dir-row-reverse@main">

                <div className="l-col-12 l-col-6@main u-pd-hz-m u-pd-vt-xl u-pd-xl@main u-flex u-flex-dir-col u-flex-center-hz">

                  <FadeInUp yOffset={50} duration={0.6}>
                    <div className="u-bordered u-bg-white u-pd-xl">
                      <h2 className="c-h2 u-uppercase u-green u-mg-bottom-m">{content.team.title}</h2>
                      {content.team.desc.map((item, index) => {
                        return (
                          <p className="u-mg-bottom-l" key={"team-desc--" + index}>{item}</p>
                        )
                      })}
                      <ul className="u-list u-list--green">
                        {content.team.list.map((item, index) => {
                          return (
                            <li className="" key={"team-list-" + index}>{item}</li>
                          )
                        })}
                      </ul>
                    </div>

                    <div className="l-grid u-bordered u-border-0-top">
                      <div className="l-col-12 l-col-6@main u-bg-white u-border-right@main u-flex u-flex-center-vt u-flex-center-hz u-pd-m">
                        <span className="u-fs-s u-semibold u-center">{content.team.punchline}</span>
                      </div>
                      <div className="l-col-12 l-col-6@main">
                        <LocalizedLink to="/equipe" className="c-btn c-btn--green u-border-0-top@main u-border-0-right u-border-0-bottom u-border-0-left u-width-full u-height-full">
                          <span className="u-fs-s">{content.team.cta} <br/> <FourchetteIcon className="u-mg-top-xs"/></span>
                          <span>{content.team.cta_hover}</span>
                        </LocalizedLink>
                      </div>
                    </div>
                  </FadeInUp>
                </div>

                <div className="l-col-12 l-col-6@main u-flex u-flex-end u-flex-center-vt u-pd-hz-m u-pd-vt-xl u-pd-xl@main">
                  <FadeInUp yOffset={50} duration={0.6} className="u-pd-l u-pd-xxl@main u-width-full u-height-full">
                    <Img className="" fluid={data.imageTeam.childImageSharp.fluid} />
                  </FadeInUp>
                </div>

              </div>

            </div>
          </IntersectionObserver>

          <section className="u-bg-white u-pd-hz-xxl">
            <div className="u-border-top u-pd-vt-xxl">
              <div className="l-container">

                <div className="u-pd-hz-m u-pd-vt-xl u-pd-xl@main u-center">
                  <h2 className="c-h2 u-mg-bottom-m">{content.roadmap.title}</h2>
                  <p className="u-mg-bottom-l">{content.roadmap.desc}</p>

                  <ul className="c-timeline u-pd-hz-l u-pd-vt-xl">
                    {content.roadmap.timeline.map((item, index) => {
                      return (
                        <li className={index === 0 ? "is-active" : ""} key={"roadmap-" + index}>
                          <div className="u-bold">{item.date}</div>
                          <div className="">{item.label}</div>
                        </li>
                      )
                    })}
                  </ul>

                  <div className="u-flex u-flex-center-hz u-flex-dir-col u-flex-dir-row@main">

                    <div className="u-pd-m">
                      <a href={data.file.childContentJson.global.subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--primary">
                        <span>{content.roadmap.cta1}</span>
                        <span>{content.roadmap.cta1_hover}</span>
                      </a>
                    </div>

                    <div className="u-pd-m">
                      <LocalizedLink to={'/programme'} className="c-btn c-btn--green" style={{ minWidth: "240px" }}>
                        <span>{content.roadmap.cta2}</span>
                        <span>{content.roadmap.cta2_hover}</span>
                      </LocalizedLink>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>

          <section className="u-border-top">
            <div className="l-container">

              <div className="l-grid u-flex-dir-row-reverse@main">

                <div className="l-col-12 l-col-6@main u-pd-xl u-flex u-flex-dir-col u-flex-center-hz">

                  <h2 className="c-h2 u-red u-mg-bottom-m">{content.recipe.title}</h2>
                  {content.recipe.desc.map((item, index) => {
                    return (
                      <p className="u-mg-bottom-l" key={"recipe-desc--" + index}>{item}</p>
                    )
                  })}
                  <ul className="u-list u-list--red u-flex u-flex-dir-col u-flex-dir-row@main u-flex-wrap">
                    {content.recipe.list.map((item, index) => {
                      return (
                        <li className="u-bold u-mg-bottom-m" key={"recipe-list-" + index} style={{ flex: "1 0 50%" }}>{item}</li>
                      )
                    })}
                  </ul>

                </div>

                <div className="l-col-12 l-col-6@main u-flex u-flex-end u-flex-center-vt u-pd-xl">
                  <div className="u-pd-l u-pd-xxl@main u-width-full u-height-full">
                    <Img className="u-bordered" fluid={data.imageProgram.childImageSharp.fluid} />
                  </div>
                </div>

              </div>

            </div>

          </section>



          {content.batches.map((batch) => {
            return (
              <section className="u-pd-bottom-xl u-bg-white u-border-top">
                <div className="l-container">

                  <div className="l-grid u-pd-vt-xxl u-pd-hz-l">
                    <div className="">
                      <h2 className="c-h2 u-bold u-mg-bottom-l u-mg-0@main">{batch.title}</h2>
                      <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}}>{batch.desc}</p>
                    </div>
                  </div>

                  <div className="l-grid u-mg-bottom-m">
                    {batch.startups.map((item, index) => {
                      const img = data.startupsLogo.nodes.find(x => x.name === item.logo) || data.startupsLogo.nodes.find(x => x.name === 'chef');
                      return (
                        <div className={"l-col-12 l-col-4@main u-pd-hz-m"} key={"startups-" + index}>
                          <div className="u-center u-mg-bottom-xl">

                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                              <Img key={img.childImageSharp.fixed.src}
                                  fixed={img.childImageSharp.fixed}
                                  imgStyle={{}}
                                  className="u-mg-bottom-m"/>
                            </a>

                            <h3 className="c-h3 u-fs-m u-bold u-italic u-mg-bottom-xs">{item.name}</h3>
                            <p className="u-fs-s u-semi-bold">{item.desc}</p>
                            <p className="u-mg-top-s u-fs-s u-semi-bold">{item.hastags.map(hashtag => (<span>&#35;{hashtag}&nbsp;</span>))}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <h3 className="c-h4 u-green80 u-uppercase u-semibold">{batch.appendix.title}</h3>
                  {batch.appendix.paragraphs.map((content, index) => (<p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}} key={`batch-appendix-p--${index}`}>{content}</p>))}

                </div>
              </section>
            )
          })}



          <section className="u-border-top">
            <div className="l-container">
              <div className="l-grid u-pd-vt-xxl u-pd-hz-l u-flex-center-vt">
                <div className="l-col-12 l-col-6@main">
                  <div className="u-fs-m u-bold u-center u-right@main u-mg-bottom-l u-mg-0@main">{content.band.punchline}</div>
                </div>
                <div className="l-col-12 l-col-2@main u-pd-hz-l u-hide u-flex@main">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-4@main u-center u-left@main">
                  <a href={data.file.childContentJson.global.subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--primary">
                    <span>{content.band.cta}</span>
                    <span>{content.band.cta_hover}</span>
                  </a>
                </div>
              </div>
            </div>
          </section>


        </div>
      </Layout>
    )
  }
}

export default ProgramPage

export const programBasicImageFragment = graphql`
  fragment programBasicImageFragment on File {
    childImageSharp {
      fluid(maxWidth: 300, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        program {
          desc
          program {
            asterisk
            cta
            cta_hover
            desc
            list
            punchline
            title
          }
          roadmap {
            cta1
            cta1_hover
            cta2
            cta2_hover
            desc
            timeline {
              date
              label
            }
            title
          }
          team {
            cta
            cta_hover
            desc
            list
            punchline
            title
          }
          band {
            cta
            cta_hover
            punchline
          }
          recipe {
            desc
            list
            title
          }
          title
          batches {
            title
            desc
            startups {
              name
              logo
              desc
              url
              hastags
            }
            appendix {
              title
              paragraphs
            }
          }
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    imageProgram: file(relativePath: { eq: "img/home/fourchette.png" }) { ...programBasicImageFragment },
    imageTeam: file(relativePath: { eq: "img/home/toque.png" }) { ...basicImageFragment },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    startupsLogo: allFile(
      filter: { absolutePath: { regex: "//startups//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
